import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { MapForm } from '../components';
import { useGetMapsQuery, useCreateMapMutation, CreateMapMutationVariables } from '../../../graphql';
import { useTranslation } from 'react-i18next';

const Create = () => {
  const { placeId } = useParams();
  const [createPlace, { data, loading }] = useCreateMapMutation();
  const { loading: mapsLoading, data: mapsData } = useGetMapsQuery({ variables: { placeId, floor: 0 } });
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      navigate(`/places/${placeId}/maps`);
    }
  }, [data]);

  const onSubmit = (dataToSubmit: CreateMapMutationVariables) => {
    createPlace({ variables: { ...dataToSubmit, placeId } });
  };

  if (loading || mapsLoading) {
    return <></>;
  }
  const defaultValues = mapsData?.maps.length > 0
    ? { ...mapsData.maps[0], name: '', floor: '' }
    : null

  return (
    <>
      <MapForm
        onSubmit={onSubmit}
        loading={loading && mapsLoading}
        title={t('createTitle', { field: t('map') })}
        defaultValues={defaultValues}
      />
    </>
  );
};
export default Create;
