import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FeatureForm } from '../components';
import {
  useCreateFeatureMutation,
  CreateFeatureMutationVariables,
  useGetPinsQuery,
  useGetMapsQuery,
  useGetLocationQuery,
  useUpdateFeatureNodesMutation,
  Location,
} from '../../../graphql';

const Create = () => {
  const { placeId, locationId, mapId } = useParams();
  const { loading: mapsLoading, data: mapsData } = useGetMapsQuery({ variables: { placeId } });
  const { loading: pinsLoading, data: pinsData } = useGetPinsQuery({ variables: { placeId: placeId } });
  const { loading: locationLoading, data: locationData } = useGetLocationQuery({ variables: { id: locationId } });
  const [createFeature, { data, loading }] = useCreateFeatureMutation();
  const [updateFeatureNodes, { data: updateFeatureNodesData }] = useUpdateFeatureNodesMutation();
  const navigate = useNavigate();

  const [nodesToUpdate, setNodesToUpdate] = useState<any[]>();

  const redirect = () => {
    if (mapId) {
      navigate(`/places/${placeId}/maps/${mapId}/features`);
    } else {
      navigate(`/places/${placeId}/locations/${locationId}/features`);
    }
  };

  useEffect(() => {
    if (data && nodesToUpdate.length > 0) {
      const featureId = data.createFeature.feature.id;
      updateFeatureNodes({ variables: { nodes: nodesToUpdate, featureId } });
    } else if (data) {
      redirect();
    }
  }, [data]);

  useEffect(() => {
    if (updateFeatureNodesData) {
      redirect();
    }
  }, [updateFeatureNodesData]);

  const onSubmit = (dataToSubmit: CreateFeatureMutationVariables & { nodes: any[] }) => {
    if (dataToSubmit.nodes) {
      setNodesToUpdate(dataToSubmit.nodes?.map((node) => [node.lat, node.lng]));
    }
    createFeature({ variables: { ...dataToSubmit, locationId } });
  };
  if (mapsLoading || pinsLoading || locationLoading) {
    return <></>;
  }
  return (
    <>
      <FeatureForm
        onSubmit={onSubmit}
        loading={loading}
        title="Crear ubicación"
        maps={mapsData.maps}
        pins={pinsData.pins}
        // @todo fix type and remove cast as Location
        location={locationData.locations[0] as Location}
        placeId={placeId}
      />
    </>
  );
};
export default Create;
