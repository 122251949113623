import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FeatureForm } from '../components';
import {
  useUpdateFeatureMutation,
  useGetFeatureQuery,
  UpdateFeatureMutationVariables,
  useGetMapsQuery,
  useGetPinsQuery,
  useUpdateFeatureNodesMutation,
} from '../../../graphql';
import { useTranslation } from 'react-i18next';

const Update = () => {
  const { t } = useTranslation();
  const { featureId, placeId, locationId, mapId } = useParams();
  const [updateFeature, { data: updateData, loading: updateLoading }] = useUpdateFeatureMutation();
  const { loading, data } = useGetFeatureQuery({ variables: { id: featureId } });
  const { loading: mapsLoading, data: mapsData } = useGetMapsQuery({ variables: { placeId } });
  const { loading: pinsLoading, data: pinsData } = useGetPinsQuery({ variables: { placeId } });
  const [updateFeatureNodes, { data: updateFeatureNodesData }] = useUpdateFeatureNodesMutation();
  const navigate = useNavigate();

  const [nodesToUpdate, setNodesToUpdate] = useState<any[]>();

  const redirect = () => {
    if (mapId) {
      navigate(`/places/${placeId}/maps/${mapId}/features`);
    } else {
      navigate(`/places/${placeId}/locations/${locationId}/features`);
    }
  };

  useEffect(() => {
    if (updateData && nodesToUpdate.length > 0) {
      updateFeatureNodes({ variables: { nodes: nodesToUpdate, featureId } });
    } else if (updateData) {
      redirect();
    }
  }, [updateData]);

  useEffect(() => {
    if (updateFeatureNodesData) {
      redirect();
    }
  }, [updateFeatureNodesData]);

  const onSubmit = (dataToSubmit: UpdateFeatureMutationVariables & { nodes: any[] }) => {
    if (dataToSubmit.nodes) {
      setNodesToUpdate(dataToSubmit.nodes?.map((node) => [node.lat, node.lng]));
    }
    updateFeature({ variables: { ...dataToSubmit, id: featureId } });
  };

  if (loading || mapsLoading || pinsLoading) {
    return <></>;
  }
  return (
    <>
      <FeatureForm
        onSubmit={onSubmit}
        loading={updateLoading}
        title={t('updateTitle', { field: t('feature') })}
        defaultValues={data?.features[0]}
        maps={mapsData?.maps}
        pins={pinsData?.pins}
        placeId={placeId}
      />
    </>
  );
};
export default Update;
